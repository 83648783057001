import React from 'react';
import {Button} from "antd";
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo l3kygcx1tx-editor_css',
    children:
      'https://codemeteors.oss-cn-beijing.aliyuncs.com/logo/logo_row.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        // subItem: [
        //   {
        //     name: 'sub0',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       href: 'https://www.codemeteors.com/blog',
        //       target: '_blank',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://codemeteors.oss-cn-beijing.aliyuncs.com/blog/logo2.png?x-oss-process=image/resize,limit_0,m_fill,w_30,h_30',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: '技术博客',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '大数据与人工智能探索',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     name: 'sub1',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       href: 'https://github.com/lcdevelop',
        //       target: '_blank',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             '',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'github',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '开源技术',
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item1',
        className: 'header1-item',
        children: {
          href: 'https://blog.codemeteors.com/',
          target: '_blank',
          children: [
            {
              children: (
                  <span>
                  <p>技术博客</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 l3kygbsxjo-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children:
      'https://codemeteors.oss-cn-beijing.aliyuncs.com/logo/logo256.png',
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>飞码流星科技，帮助有志者实现创业梦</p>
      </span>
    ),
  },
  button: { className: 'banner0-button', children: '产品与服务', href: '#product_and_service' },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'IT技术咨询服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>服务内容</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'content0-block-content',
              children: (
                <span>
                  <p>技术方案设计、实施、交付。</p>
                  <p>技术团队管理方案设计与规划。</p>
                  <p>技术人员招聘面试、背调、评估。</p>
                  <p>互联网技术项目外包研发。</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>服务价格</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>架构师咨询：10000元/日</p>
                  <p>架构师驻场：100000元/月</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>联系方式</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>邮箱：admin@codemeteors.com</p>
                  <p>微信：warmheartli</p>
                  <Button type={'primary'}>下单预约</Button>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '技术服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>企业数字化转型</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>域名注册、空间托管、服务器租用、ICP备案、OA、CRM、ERP系统定制开发</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>互联网创业孵化</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>微信小程序定制开发、企业网站设计与开发、移动网站设计与开发、微商城制作</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>品牌宣传与推广</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>LOGO设计、画册设计、VI设计</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>成功案例</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>助力创业者实现梦想</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>东水源景区小程序</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <img src='https://codemeteors.oss-cn-beijing.aliyuncs.com/official_website/gh_f61235767184_258.jpg' className='qrcode-img'/>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>汽配物流小程序</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <img src='https://codemeteors.oss-cn-beijing.aliyuncs.com/official_website/gh_bd3433f976bb_258.jpg' className='qrcode-img'/>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p><a href='http://www.hlhrjy.com/' target='_blank'>非遗教育智慧管理平台(PC)</a></p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>多端联动：官网端、总部管理端、机构端、老师端</p>
                <p>技术优势：官网特效、定制播放器</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>汽修黄页小程序</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <img src='https://codemeteors.oss-cn-beijing.aliyuncs.com/official_website/gh_133a31902312_258.jpg' className='qrcode-img'/>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>医美体测小程序</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <img src='https://codemeteors.oss-cn-beijing.aliyuncs.com/official_website/gh_14982c6ac8a2_344.jpg' className='qrcode-img'/>
              </span>
            ),
          },
        },
      },
      {
        name: 'block93',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>英语拼单词小程序(内测中)</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>游戏化拼单词，打榜分享</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block94',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p><a href='https://h5.mjingjie.cn/' target='_blank'>数字藏品服务平台(h5)</a></p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>依托腾讯至信链，为品牌和IP提供NFT应用场景</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block95',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p><a href='http://www.heshenghailai.com/' target='_blank'>医美企业官网(PC)</a></p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>助力企业信息化转型，建设优秀品牌形象</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p><a href='http://cloud.codemeteors.com/towergame/' target='_blank'>星座屋互动游戏(h5)</a></p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>为某国有银行营销活动打造</p>
                  <p>技术亮点：h5互动性，提高用户粘性</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p><a href='https://cloud.codemeteors.com/automobile/' target='_blank'>汽车吞噬者互动游戏(h5)</a></p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>为某国有银行营销活动打造</p>
                  <p>技术亮点：h5互动性，提高用户粘性</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block8',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p><a href='https://evertabs.codemeteors.com/' target='_blank'>EverTab浏览器标签管理插件</a></p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>EverTabs是chrome浏览器上的一款插件</p>
                  <p>能够把Tab标签页分不同的工作空间来组织</p>
                </span>
              </span>
            ),
          },
        },
      }
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
          ©2022 codemeteors.com All Rights Reserved Email:<a href="mailto:admin@codemeteors.com">admin@codemeteors.com</a> 微信: warmheartli
          <a href='https://beian.miit.gov.cn/' target='_blank'>京ICP备2021040032号-1</a>
      </span>
    ),
  },
};
